import { createTheme, useMediaQuery } from '@mui/material'

const alizPalette = {
  primary: {
    main: '#052120',
  },
  secondary: {
    light: '#052120', // form input helper text
    main: '#0000001f', // disabled button
    dark: '#697281', // input text
    contrastText: '#00000061', // disabled button text
  },
  warning: {
    main: '#f2d142',
  },
  error: {
    main: '#fd8f82',
    contrastText: '#fff',
  },
  info: {
    main: '#02394d',
  },
  success: {
    main: '#8cac9c',
    contrastText: '#fff',
  },
} as const

const lightTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 'max-content',
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1315,
    },
  },
  palette: {
    mode: 'light',
    ...alizPalette,
    background: {
      default: '#E7EAF0',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
      letterSpacing: '0.25px',
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    subtitle1: {
      letterSpacing: '0.15px',
      lineHeight: '1.2',
      fontSize: '1.25rem',
    },
    body1: {},
    button: {
      lineHeight: '26px',
      fontSize: '15px',
      fontWeight: 500,
      textTransform: 'none',
    },
    caption: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: '12px',
      lineHeight: '0.5',
      letterSpacing: '0.15px',
    },
  },
  glass: {
    background: (opacity) => `rgba(255,255,255,${opacity})`,
    opacity: 0.4,
    borderColor: 'rgba(255,255,255,0.3)',
    shadow: 'rgba(0,0,0,0.1)',
  },
  appBackground: {
    brightnessModifier: 0,
  },
})

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    ...alizPalette,
  },
  glass: {
    background: (opacity) => `rgba(0,0,0,${opacity})`,
    opacity: 0.3,
    borderColor: '1px solid rgba(0,0,0,0.3)',
    shadow: 'rgba(0,0,0,0.2)',
  },
  appBackground: {
    brightnessModifier: -0.5,
  },
})

export const useColorMode = <T>(light: T, dark: T) => (useMediaQuery('(prefers-color-scheme: dark)') ? dark : light)

export const useTheme = () => {
  let preferredMode = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light'

  //TODO finish dark theme
  preferredMode = 'light'

  return preferredMode === 'light' ? lightTheme : darkTheme
}
