import { Model } from './Model'

export interface User {
  userEmail: string
  serviceAccountEmail: string
  googleAnalyticsExport: GoogleBigqueryExport
  googleAnalyticsAccount: GoogleAnalyticsAccount
  models?: Model[]
}

interface GoogleBigqueryExport {
  projectId: string
  datasetId: string
  location: string
}

interface GoogleAnalyticsAccount {
  accountId: string
  propertyId: string
  customDimensionIndex: number
  trackingType: string
  joinKeyScope: string
}

export const findModel = (user: User, configId: string): Model | undefined => {
  return user.models?.find((model) => model.configId === configId)
}
