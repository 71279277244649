import { AuthContext } from './AuthContext'
import {
  Box,
  Button,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { Logout } from '@mui/icons-material'
import { auth } from '../../config/firebaseSetup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import React, { useContext } from 'react'

export function AccountDropDown() {
  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [dropWidth, setDropWidth] = React.useState(0)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setDropWidth(event.currentTarget.offsetWidth)
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Tooltip title="Account settings">
          <Button
            id="account-button"
            onClick={handleClick}
            size="large"
            sx={{ ml: 2, borderRadius: '64px', backgroundColor: '#FAFAFA' }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Typography variant="button" color="primary">
              {firebaseUser?.email}
            </Typography>
            <ArrowDropDownIcon />
          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <ListItem sx={{ width: dropWidth }}>
          <ListItemText primary="Google Account Name" secondary={firebaseUser?.email} />
        </ListItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Button component={Link} to="/login" onClick={() => auth.signOut()}>
            Sign out
          </Button>
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}
