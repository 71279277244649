import * as Yup from 'yup'
import { AppMenu } from '../common/components/atoms/AppMenu'
import { AuthContext } from '../common/components/atoms/AuthContext'
import { Box, Button, InputAdornment, Link as MuiLink, Stack, TextField, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { LocalHeader } from '../common/components/molecules/LocalHeader'
import { ProgressBarProps } from '../common/components/atoms/ProgressBar'
import { QueryError, ValidationStatus } from './BigqueryConfiguration'
import { ValidationCircularSpinner } from '../common/components/atoms/ValidationCircularSpinner'
import { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'

import { ImageContainer } from '../common/theme/ImageContainer'
import { Model } from '../common/data/Model'
import { User } from '../common/data/User'
import { getCurrentUser } from '../common/query/current-user'
import { useNavigate, useParams } from 'react-router-dom'
import { useNavigateToCorrectPath } from '../common/hooks/use-navigate'
import HelperModal from '../common/components/atoms/HelperModal'

interface ModelCreationFormValues {
  gaImportDatasetId: string
  gaCustomDimensionId: string
}

export const ModelConfiguration = () => {
  const [currentUser, setCurrentUser] = useState<User>()
  const { configId } = useParams()

  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null

  useEffect(() => {
    getCurrentUser(firebaseUser).then((data) => setCurrentUser(data))
  }, [firebaseUser])

  useNavigateToCorrectPath(currentUser, '/model-configuration', configId)
  const navigate = useNavigate()

  const [status, setStatus] = useState<ValidationStatus>('initial')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const tabList = ['Models']

  const progressBarProps: ProgressBarProps = {
    numberOfSteps: 2,
    stepsCompleted: 1,
  }

  const textFieldColor = status === 'error' ? 'error' : status === 'success' ? 'success' : 'primary'

  const initialValues: ModelCreationFormValues = {
    gaImportDatasetId: '',
    gaCustomDimensionId: '',
  }

  const alreadyCreatedDimensionIds = currentUser?.models?.map((model) => model.gaCustomDimensionId) ?? []

  const validationSchema = Yup.object({
    gaImportDatasetId: Yup.string().required('Required'),
    gaCustomDimensionId: Yup.number()
      .required('Required')
      .notOneOf(alreadyCreatedDimensionIds)
      .label('Custom dimension id'),
  })

  const onSubmit = async (values: ModelCreationFormValues) => {
    if (status === 'success') {
      navigate('/model-configuration-done')
    } else {
      const fetchResponse: Model | QueryError = await sendForm(values)
      setLoading(false)

      if (Object.hasOwn(fetchResponse, 'configId')) {
        setStatus('success')
      } else if (Object.hasOwn(fetchResponse, 'message')) {
        setStatus('error')
        setError((fetchResponse as QueryError).message)
      }
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })

  const sendForm = async (modelCreationFormValues: ModelCreationFormValues) => {
    if (!firebaseUser) {
      throw new Error('User is not logged in')
    }
    setLoading(true)
    const token = await firebaseUser.getIdToken()
    try {
      const url = new URL(`/api/models/v1/configs/${configId}`, process.env.API_URL)
      const fetchResponse = await fetch(url.toString(), {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(modelCreationFormValues),
      })
      return await fetchResponse.json()
    } catch (e) {
      throw new Error('Failed to post model data', { cause: e as Error })
    }
  }

  const inputFieldHelper = (url: URL, imageName: string) => {
    return {
      endAdornment: (
        <InputAdornment position="end">
          <HelperModal url={url} imageName={imageName} />
        </InputAdornment>
      ),
    }
  }

  return (
    <>
      {firebaseUser && (
        <>
          <AppMenu tabs={tabList} activeTab={tabList.indexOf('Models')} />
          <Box
            sx={{
              bgcolor: 'background.paper',
              maxWidth: '1080px',
              width: '100%',
              margin: 'auto',
              borderRadius: 1,
              p: 3,
            }}
          >
            <LocalHeader progressBar={progressBarProps} title="Model configuration" />
            <Typography color="secondary.light" sx={{ m: '15px 0px' }} variant="body1">
              Set up a{' '}
              <MuiLink
                color="secondary.light"
                href="https://support.google.com/analytics/answer/6064692?hl=en&ref_topic=6064618#step_by_step&zippy=%2Cin-this-article%2Cclick-to-see-an-overview-of-data-set-terms-and-concepts"
                variant="body1"
                rel="noopener"
                target="_blank"
              >
                Data Import
              </MuiLink>{' '}
              dataset and a{' '}
              <MuiLink
                color="secondary.light"
                href="https://support.google.com/analytics/answer/2709829?hl=en#zippy=%2Cin-this-article"
                variant="body1"
                rel="noopener"
                target="_blank"
              >
                custom dimension
              </MuiLink>{' '}
              where the propensity values will be stored in GA. Make sure the dimension’s scope matches the scope of the
              custom dimension which stores your ClientID.
            </Typography>
            <Box margin="auto" maxWidth="1080px">
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  color={textFieldColor}
                  focused={status === 'success'}
                  sx={{ mb: 2 }}
                  fullWidth
                  id="gaImportDatasetId"
                  name="gaImportDatasetId"
                  label="Dataset Id"
                  value={formik.values.gaImportDatasetId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.gaImportDatasetId && Boolean(formik.errors.gaImportDatasetId)}
                  helperText={formik.touched.gaImportDatasetId && formik.errors.gaImportDatasetId}
                  disabled={loading}
                  InputProps={inputFieldHelper(ImageContainer.importDatasetId, 'Dataset id')}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  color={textFieldColor}
                  focused={status === 'success'}
                  sx={{ mb: 2 }}
                  fullWidth
                  id="gaCustomDimensionId"
                  name="gaCustomDimensionId"
                  label="Custom Dimension ID for Uploaded Model Scores"
                  value={formik.values.gaCustomDimensionId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.gaCustomDimensionId && Boolean(formik.errors.gaCustomDimensionId)}
                  helperText={formik.touched.gaCustomDimensionId && formik.errors.gaCustomDimensionId}
                  disabled={loading}
                />

                <Stack
                  flexDirection="row"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: '24px',
                  }}
                >
                  <Button component={Link} to="/trained-model-dashboard" variant="contained" color="secondary">
                    Back to Dashboard
                  </Button>

                  {!loading && status === 'error' && (
                    <Typography variant="body2" sx={{ mr: 2, color: 'error.main' }}>
                      {error} Please check your inputs
                    </Typography>
                  )}
                  {!loading && status === 'success' && (
                    <Typography variant="body2" sx={{ mr: 2, color: 'success.main' }}>
                      Everything looks good!
                    </Typography>
                  )}
                  <Button
                    disabled={!(formik.isValid && formik.dirty) || loading}
                    type="submit"
                    variant="contained"
                    color={status === 'success' ? 'success' : 'error'}
                  >
                    {!loading && status === 'initial' && <span>Check</span>}
                    {loading && (
                      <span>
                        <ValidationCircularSpinner /> {'Validating'}
                      </span>
                    )}
                    {!loading && status === 'error' && <span>Check Again</span>}
                    {!loading && status === 'success' && <span>Proceed Further</span>}
                  </Button>
                </Stack>
              </form>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}
