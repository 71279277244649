// import './reportWebVitals'
import { App } from './app/App'
import { createRoot } from 'react-dom/client'
import React from 'react'

const root = createRoot(document.getElementById('root') as Element)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(new URL('./service-worker.ts', import.meta.url), { type: 'module' })
  })
}
