import { useQuery } from 'react-query'
import firebase from 'firebase/compat'

export const useTrackingTypes = (user: firebase.User | null) => {
  return useQuery<string[], unknown>(
    ['tracking-types', 'list'],
    async () => {
      if (!user) {
        throw new Error('User is not logged in')
      }

      try {
        const apiUrl = new URL(`/api/v1/analytics/trackingtypes`, process.env.API_URL)
        const token = await user.getIdToken()
        const fetchResponse = await fetch(apiUrl.toString(), {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        if (fetchResponse.ok) {
          return (await fetchResponse.json()) ?? []
        } else {
          throw new Error(fetchResponse.statusText)
        }
      } catch (e) {
        throw new Error('Failed get results for tracking types.', { cause: e as Error })
      }
    },
    {
      enabled: true,
      staleTime: 60 * 60 * 1000,
      keepPreviousData: true,
    }
  )
}
