import { Button, ButtonGroup, Grid, Stack } from '@mui/material'
import { Link } from 'react-router-dom'

export const TempPageMenu = () => {
  return (
    <Stack
      sx={{
        bgcolor: 'background.paper',
        maxWidth: '1080px',
        width: '100%',
        margin: 'auto',
        borderRadius: '8px',
        mb: '32px',
        mt: '0px',
      }}
    >
      <Grid container justifyContent="flex-end">
        <ButtonGroup variant="outlined" color="secondary" sx={{ p: 1 }}>
          <Button component={Link} to="/login" variant="contained" color="primary">
            Login
          </Button>
          <Button component={Link} to="/grant-permission-bigquery" variant="contained" color="primary">
            GrPerm BQ
          </Button>
          <Button component={Link} to="/bigquery-configuration" variant="contained" color="primary">
            BQ
          </Button>
          <Button component={Link} to="/grant-permission-analytics" variant="contained" color="primary">
            GrPerm An
          </Button>
          <Button component={Link} to="/analytics-configuration" variant="contained" color="primary">
            Analytics
          </Button>
          <Button component={Link} to="/configuration-done" variant="contained" color="primary">
            Conf done
          </Button>
          <Button component={Link} to="/trained-model-dashboard" variant="contained" color="primary">
            TrModel
          </Button>
          <Button component={Link} to="/model-type-configuration" variant="contained" color="primary">
            ModelTypeConf
          </Button>
          <Button component={Link} to="/model-configuration-done" variant="contained" color="primary">
            Model Conf done
          </Button>
          <Button component={Link} to="/settings" variant="contained" color="primary">
            Set
          </Button>
        </ButtonGroup>
      </Grid>
    </Stack>
  )
}
