import * as firebaseui from 'firebaseui'
import { AuthContext } from '../common/components/atoms/AuthContext'
import { Box } from '@mui/system'
import { Card, CardMedia, Grid, Stack, Typography, useTheme } from '@mui/material'
import { SimpleYoutube } from '../common/components/atoms/SimpleYoutube'
import { useContext, useEffect } from 'react'
import firebase from 'firebase/compat'
import loginImage from '../../assets/login.png'

export const Login = ({ auth }: { auth: firebase.auth.Auth }) => {
  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null
  const theme = useTheme()
  const DEMO_VIDEO_ID = 'UF1VaabdQNU'

  useEffect(() => {
    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth)

    ui.start('.firebase-auth-container', {
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        },
      ],
      signInSuccessUrl: '/page-router',
    })
  }, [auth])

  const imageUrl = new URL(loginImage, import.meta.url)

  return (
    <>
      {!firebaseUser && (
        <Box margin="auto" maxWidth="1080px">
          <Grid container justifyContent="center" alignItems="center" columns={{ xs: 1, md: 2 }} direction="row">
            <Grid xs={1} item>
              <Typography variant="h3">Targeted Advertising Solution 🎯</Typography>
              <Typography sx={{ p: '24px 0px' }} variant="h6">
                Are you curious how does it work? Check out our demo video first!
              </Typography>
              <SimpleYoutube videoId={DEMO_VIDEO_ID} />
              <Stack sx={{ pt: '24px' }} direction="row" justifyContent="center" alignItems="center">
                <Typography sx={{ p: '16px 0px' }} variant="h5">
                  Ready to try it out?
                </Typography>
              </Stack>
              <div className="firebase-auth-container"></div>
            </Grid>
            <Grid sx={{ pl: '12px' }} item xs={1}>
              <Card sx={{ boxShadow: 0 }}>
                <div style={{ position: 'relative', backgroundColor: theme.palette.background.paper }}>
                  <CardMedia
                    component="img"
                    image={imageUrl.href}
                    title="Targeted Advertising Solution"
                    alt="Targeted Advertising Solution"
                    style={{ borderRadius: '50px 0px' }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      color: 'white',
                      bottom: 0,
                      padding: '0px 32px 32px 32px',
                    }}
                  >
                    <Typography variant="h4">Are you ready to boost your campaign? 👌</Typography>
                    <Typography sx={{ pt: '8px' }} variant="subtitle1">
                      The tool will help you to optimize your digital campaigns leveraging machine learning.
                    </Typography>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}
