import * as Yup from 'yup'
import { AppMenu } from '../common/components/atoms/AppMenu'
import { AuthContext } from '../common/components/atoms/AuthContext'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { LocalHeader } from '../common/components/molecules/LocalHeader'
import { ModelTypeCard } from '../common/components/organisms/ModelTypeCard'
import { ProgressBarProps } from '../common/components/atoms/ProgressBar'
import { QueryError, ValidationStatus } from './BigqueryConfiguration'
import { ValidationCircularSpinner } from '../common/components/atoms/ValidationCircularSpinner'
import { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'

import { Link, useNavigate } from 'react-router-dom'
import { Model } from '../common/data/Model'
import { User } from '../common/data/User'
import { getCurrentUser } from '../common/query/current-user'
import { useNavigateToCorrectPath } from '../common/hooks/use-navigate'
import HelperModal from '../common/components/atoms/HelperModal'
import eventPropensityImage from '../../assets/event_propensity.png'
import highValuePurchaserImage from '../../assets/high_value_purchaser.png'
import pagePropensityImage from '../../assets/page_propensity.png'
import purchasePropensityImage from '../../assets/purchase_propensity.png'
import returnPropensityImage from '../../assets/return_propensity.png'

const modelTypeDetails = [
  {
    value: 'PURCHASE_PROPENSITY',
    title: 'Purchase Propensity',
    description: 'Understand your customers’ likelihood to purchase',
    imagePath: purchasePropensityImage,
  },
  {
    value: 'RETURN_PROPENSITY',
    title: 'Return Propensity',
    description: 'Predict the probability of a customer returning to your website',
    imagePath: returnPropensityImage,
  },
  {
    value: 'HIGH_VALUE_PROPENSITY',
    title: 'High Value Purchaser',
    description: 'Identify customers who are likely to spend much in the future',
    imagePath: highValuePurchaserImage,
  },
  {
    value: 'EVENT_PROPENSITY',
    title: 'Event Propensity',
    description: 'Predict propensity for your custom event e.g. fill in a contact form',
    imagePath: eventPropensityImage,
    isComingSoon: true,
  },
  {
    value: 'PAGE_PROPENSITY',
    title: 'Page Propensity',
    description: 'Propensity of customers visiting a selected page on your website e.g. specific landing page',
    imagePath: pagePropensityImage,
    isComingSoon: true,
  },
]

const modelTypeValues = [
  'PURCHASE_PROPENSITY',
  'RETURN_PROPENSITY',
  'HIGH_VALUE_PROPENSITY',
  'EVENT_PROPENSITY',
  'PAGE_PROPENSITY',
]
export type ModelTypeValue = (typeof modelTypeValues)[number]

export const ModelTypeConfiguration = () => {
  const [currentUser, setCurrentUser] = useState<User>()

  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null

  useEffect(() => {
    getCurrentUser(firebaseUser).then((data) => setCurrentUser(data))
  }, [firebaseUser])

  useNavigateToCorrectPath(currentUser, '/trained-model-dashboard')
  const navigate = useNavigate()

  const [status, setStatus] = useState<ValidationStatus>('initial')
  const [configId, setConfigId] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  interface ModelCreationFormValues {
    modelType: ModelTypeValue | ''
    modelName: string
  }

  const tabList = ['Models']

  const textFieldColor = status === 'error' ? 'error' : status === 'success' ? 'success' : 'primary'

  const progressBarProps: ProgressBarProps = {
    numberOfSteps: 2,
    stepsCompleted: 0,
  }

  const initialValues: ModelCreationFormValues = {
    modelType: '',
    modelName: '',
  }

  const alreadyCreatedModelNames = currentUser?.models?.map((model) => model.modelName) ?? []

  const validationSchema = Yup.object({
    modelType: Yup.string().required('Required').oneOf(modelTypeValues).label('Model type'),
    modelName: Yup.string().required('Required').notOneOf(alreadyCreatedModelNames).label('Model name'),
  })

  const onSubmit = async (values: ModelCreationFormValues) => {
    if (status === 'success') {
      navigate('/model-configuration/' + configId)
    } else {
      const fetchResponse: Model | QueryError = await sendForm(values)
      setLoading(false)

      if (Object.hasOwn(fetchResponse, 'configId')) {
        setStatus('success')
        setConfigId((fetchResponse as Model).configId)
      } else if (Object.hasOwn(fetchResponse, 'message')) {
        setStatus('error')
        setError((fetchResponse as QueryError).message)
      }
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })

  const modelTypeCards = modelTypeDetails.map((modelType, i) => {
    return (
      <Grid item xs={1} key={i}>
        <FormControlLabel
          sx={{
            borderRadius: '5px',
            border: modelType.value === formik.values.modelType ? '1px solid grey' : 'primary',
          }}
          value={modelType.value}
          disabled={modelType.isComingSoon}
          control={
            <ModelTypeCard
              title={modelType.title}
              description={modelType.description}
              imagePath={modelType.imagePath}
              isSelected={modelType.value === formik.values.modelType}
              isComingSoon={modelType.isComingSoon}
            />
          }
          onClick={() => {
            if (modelType.isComingSoon) return
            formik.values.modelName = modelType.title
            formik.touched.modelName = true
            formik.setFieldValue('modelType', modelType.value)
          }}
          label=""
        />
      </Grid>
    )
  })

  const sendForm = async (modelCreationFormValues: ModelCreationFormValues) => {
    if (!firebaseUser) {
      throw new Error('User is not logged in')
    }
    setLoading(true)
    const token = await firebaseUser.getIdToken()
    try {
      const url = new URL(`/api/models/v1/configs`, process.env.API_URL)

      const fetchResponse = await fetch(url.toString(), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(modelCreationFormValues),
      })

      return await fetchResponse.json()
    } catch (e) {
      throw new Error('Failed to post model type and name', { cause: e as Error })
    }
  }

  const inputFieldHelperWithTextOnly = (text: string) => {
    return {
      endAdornment: (
        <InputAdornment position="end">
          <HelperModal description={text} />
        </InputAdornment>
      ),
    }
  }

  return (
    <>
      {firebaseUser && (
        <>
          <AppMenu tabs={tabList} activeTab={tabList.indexOf('Models')} />
          <Box
            sx={{
              bgcolor: 'background.paper',
              maxWidth: '1080px',
              width: '100%',
              margin: 'auto',
              borderRadius: 1,
              p: 3,
            }}
          >
            <LocalHeader progressBar={progressBarProps} title="Model configuration" />
            <Typography sx={{ m: '15px 0px' }} variant="body1">
              Firstly select your marketing objective. It will determine the purpose of your newly created target
              audience.
            </Typography>
            <Stack margin="auto" maxWidth="1080px">
              <form onSubmit={formik.handleSubmit}>
                <FormControl sx={{ p: '12px' }}>
                  <RadioGroup
                    sx={{ mb: 3, display: 'flex', flexDirection: 'row' }}
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="modelType"
                    value={formik.values.modelType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <Grid container rowSpacing={{ xs: 3 }} columnSpacing={{ xs: 6 }} columns={{ xs: 1, md: 2, lg: 3 }}>
                      {modelTypeCards}
                    </Grid>
                  </RadioGroup>
                </FormControl>

                <TextField
                  InputLabelProps={{ shrink: true }}
                  color={textFieldColor}
                  focused={status === 'success'}
                  sx={{ mb: 2 }}
                  fullWidth
                  placeholder={formik.values.modelType === '' ? 'Please choose model type first' : 'e.g. My model name'}
                  id="modelName"
                  name="modelName"
                  label="Model name"
                  value={formik.values.modelName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.modelName && Boolean(formik.errors.modelName)}
                  helperText={formik.touched.modelName && formik.errors.modelName}
                  disabled={loading || formik.values.modelType === ''}
                  InputProps={inputFieldHelperWithTextOnly(
                    'The name of your model. You can choose any name you think, that can help you differentiate from other models.'
                  )}
                />

                <Stack
                  flexDirection="row"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: '24px',
                  }}
                >
                  <Button component={Link} to="/trained-model-dashboard" variant="contained" color="secondary">
                    Cancel
                  </Button>

                  {!loading && status === 'error' && (
                    <Typography variant="body2" sx={{ mr: 2, color: 'error.main' }}>
                      {error}! Please check your inputs
                    </Typography>
                  )}
                  {!loading && status === 'success' && (
                    <Typography variant="body2" sx={{ mr: 2, color: 'success.main' }}>
                      Everything looks good!
                    </Typography>
                  )}

                  <Button
                    disabled={!(formik.isValid && formik.dirty) || loading}
                    type="submit"
                    variant="contained"
                    color={status === 'success' ? 'success' : 'error'}
                  >
                    {!loading && status === 'initial' && <span>Check</span>}
                    {loading && (
                      <span>
                        <ValidationCircularSpinner /> {'Validating'}
                      </span>
                    )}
                    {!loading && status === 'error' && <span>Check Again</span>}
                    {!loading && status === 'success' && <span>Proceed Further</span>}
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
        </>
      )}
    </>
  )
}
