import {
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { ModelTypeValue } from '../../../pages/ModelTypeConfiguration'
import { User } from '../../data/User'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DeleteModal from '../atoms/DeleteModal'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'

const modelTypeMap: Map<ModelTypeValue, string> = new Map()
modelTypeMap.set('PURCHASE_PROPENSITY', 'Purchase Propensity')
modelTypeMap.set('RETURN_PROPENSITY', 'Return Propensity')
modelTypeMap.set('HIGH_VALUE_PROPENSITY', 'High Value Purchaser')
modelTypeMap.set('EVENT_PROPENSITY', 'Event Propensity')
modelTypeMap.set('PAGE_PROPENSITY', 'Page Propensity')

const colors = ['primary', 'success', 'warning', 'error'] as const
type Color = (typeof colors)[number]

const provisionStatusDetails: Map<string, { text: string; color: Color }> = new Map()
provisionStatusDetails.set('RUNNING', { text: 'Provisioning', color: 'primary' })
provisionStatusDetails.set('FINISHED', { text: 'In operation', color: 'success' })
provisionStatusDetails.set('WAITING_FOR_APPROVAL', { text: 'Provisioning needs approval', color: 'warning' })
provisionStatusDetails.set('REJECTED', { text: 'Provisioning rejected', color: 'error' })
provisionStatusDetails.set('ERROR', { text: 'Provisioning failed', color: 'error' })
provisionStatusDetails.set('MARKED_FOR_DELETION', { text: 'Marked for deletion', color: 'error' })

const fontStyle = { fontWeight: '500', fontSize: '14px', lineHeight: '24px', fontFamily: 'Roboto, sans-serif' }

interface ModelTableProps {
  currentUser: User
  clickHandler: () => void
}

export const ModelTable = (props: ModelTableProps) => {
  const { currentUser } = props

  const clickHandler = () => {
    props.clickHandler()
  }

  const models = currentUser?.models || []
  models.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
  const modelJsx = models.map((model) => {
    const modelConfigurationUrl = `/model-configuration/${model.configId}`

    return (
      <TableRow key={model.configId}>
        <TableCell component="td" scope="row">
          {model.provisionStatus === 'FINISHED' ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <RadioButtonUncheckedIcon sx={{ color: 'secondary.main' }} />
          )}
        </TableCell>
        <TableCell component="td" scope="row">
          {model.modelName}
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2">{modelTypeMap.get(model.modelType)}</Typography>
        </TableCell>
        <TableCell align="left">
          {!model.gaImportDatasetId && (!model.provisionStatus || model.provisionStatus !== 'MARKED_FOR_DELETION') && (
            <Button component={Link} to={modelConfigurationUrl} variant="contained" color="info">
              Finish Configuration
            </Button>
          )}
          {model.gaImportDatasetId && !model.provisionStatus && (
            <Chip style={{ border: '2px solid' }} label="Provisioning" variant="outlined" color="primary" />
          )}
          {model.provisionStatus && (
            <Chip
              style={{ border: '2px solid' }}
              label={provisionStatusDetails.get(model.provisionStatus)?.text}
              variant="outlined"
              color={provisionStatusDetails.get(model.provisionStatus)?.color}
            />
          )}
        </TableCell>

        <TableCell align="left">
          <DeleteModal
            clickHandler={clickHandler}
            configId={model.configId}
            type="model"
            name={model.modelName}
            disabled={model.provisionStatus === 'MARKED_FOR_DELETION'}
          />
        </TableCell>
      </TableRow>
    )
  })

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={fontStyle}>Active</TableCell>
            <TableCell sx={fontStyle} align="left">
              Model Name
            </TableCell>
            <TableCell sx={fontStyle} align="left">
              Model Type
            </TableCell>
            <TableCell sx={fontStyle} align="left">
              Status
            </TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        {currentUser && <TableBody>{modelJsx}</TableBody>}
      </Table>
    </TableContainer>
  )
}
