import { AuthContext } from '../common/components/atoms/AuthContext'
import { User } from '../common/data/User'
import { ValidationCircularSpinner } from '../common/components/atoms/ValidationCircularSpinner'
import { createUser, getCurrentUser } from '../common/query/current-user'
import { getConfigStatusOfUser, getCorrectPathOfUser } from '../common/query/status-router'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const PageRouter = () => {
  const [currentUser, setCurrentUser] = useState<User>()
  const [waitingForUserCreation, setWaitingForUserCreation] = useState(false)

  const navigate = useNavigate()
  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null

  useEffect(() => {
    getCurrentUser(firebaseUser).then((data) => setCurrentUser(data))
  }, [firebaseUser])

  useEffect(() => {
    if (currentUser) {
      const status = getConfigStatusOfUser(currentUser)

      if (status === 'unregistered') {
        setWaitingForUserCreation(true)
        createUser(firebaseUser).then((data) => setCurrentUser(data))
      } else {
        const correctPath = getCorrectPathOfUser(currentUser)
        if (process.env.FREE_NAVIGATION_ALLOWED === 'false') {
          navigate(correctPath)
        }
      }
    }
  }, [currentUser, navigate, firebaseUser])

  return (
    <>
      {waitingForUserCreation && (
        <>
          <span>
            <ValidationCircularSpinner /> {'Please wait while we are creating your account...'}
          </span>
        </>
      )}
    </>
  )
}
