import { AuthContext } from './AuthContext'
import { Button, Chip, Typography } from '@mui/material'
import { QueryError, ValidationStatus } from '../../../pages/BigqueryConfiguration'
import { Stack } from '@mui/system'
import { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone'
import Modal from '@mui/material/Modal'

interface DeleteProps {
  configId: string
  type: string
  name: string
  clickHandler: () => void
  disabled: boolean
}

export default function DeleteModal(props: DeleteProps) {
  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [status, setStatus] = useState<ValidationStatus>('initial')
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleDelete = async () => {
    if (!firebaseUser) {
      throw new Error('User is not logged in')
    }
    setLoading(true)

    const token = await firebaseUser.getIdToken()
    try {
      const url = new URL(`/api/models/v1/configs/${props.configId}`, process.env.API_URL)

      const fetchResponse = await fetch(url.toString(), {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })

      if (fetchResponse.ok) {
        handleReset()
        props.clickHandler()
      } else {
        const response = await fetchResponse.json()
        setLoading(false)
        if (Object.hasOwn(response, 'message')) {
          setStatus('error')
          setError((response as QueryError).message)
        }
      }
    } catch (e) {
      throw new Error('Failed to delete model', { cause: e as Error })
    }
  }

  const handleReset = () => {
    setStatus('initial')
    setError('')
    handleClose()
  }

  const modelName = <Chip style={{ border: '1px solid' }} label={props.name} variant="filled" size="small" />

  return (
    <>
      {!props.disabled && (
        <DeleteTwoToneIcon
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          color="error"
          onClick={handleOpen}
        />
      )}
      {props.disabled && <DeleteOutlineIcon sx={{ color: 'secondary.main' }} />}
      <Modal open={open} onClose={handleReset}>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
          }}
        >
          <Typography sx={{ p: '16px 24px' }} id="modal-title" variant="h6" component="h2">
            Do you really want to delete this {props.type}: {modelName} ?
          </Typography>
          <Stack
            flexDirection="row"
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              mt: '24px',
            }}
          >
            {!loading && status === 'error' && (
              <>
                <Typography variant="body2" sx={{ ml: 2, mr: 2, color: 'error.main' }}>
                  {error} Please try again later!
                </Typography>
                <Button variant="contained" sx={{ m: '14px' }} color="error" onClick={handleReset}>
                  Close
                </Button>
              </>
            )}
            {!loading && status !== 'error' && (
              <>
                <Button variant="contained" sx={{ m: '14px' }} color="error" onClick={handleDelete}>
                  Yes, delete!
                </Button>
                <Button variant="outlined" sx={{ m: '14px' }} color="error" onClick={handleClose}>
                  No
                </Button>
              </>
            )}
          </Stack>
        </Box>
      </Modal>
    </>
  )
}
