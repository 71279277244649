import { Grid, Typography } from '@mui/material'
import { ProgressBar, ProgressBarProps } from '../atoms/ProgressBar'

interface LocalHeaderProps {
  progressBar?: ProgressBarProps
  title: string
}

export const LocalHeader = (props: LocalHeaderProps) => {
  return (
    <Grid sx={{ mb: '24px' }} spacing={1} container>
      <Grid xs item>
        <Typography variant="h5" color="primary">
          {props.title}
        </Typography>
      </Grid>
      <Grid xs item>
        {props.progressBar && <ProgressBar {...props.progressBar} />}
      </Grid>
    </Grid>
  )
}
