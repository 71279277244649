import firebase from 'firebase/compat'

export const getCurrentUser = async (user: firebase.User | null) => {
  if (!user) {
    throw new Error('User is not logged in')
  }

  try {
    const apiUrl = new URL(`/api/v1/users/current`, process.env.API_URL)
    const token = await user.getIdToken()
    const fetchResponse = await fetch(apiUrl.toString(), {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    if (fetchResponse.ok) {
      const response = await fetchResponse.json()
      return response
    } else {
      return {}
    }
  } catch (e) {
    throw new Error('Failed get results for user: ' + user.email, { cause: e as Error })
  }
}

export const createUser = async (user: firebase.User | null) => {
  if (!user) {
    throw new Error('User is not logged in')
  }

  try {
    const apiUrl = new URL(`/api/v1/users`, process.env.API_URL)
    const token = await user.getIdToken()
    const fetchResponse = await fetch(apiUrl.toString(), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    if (fetchResponse.ok) {
      const response = await fetchResponse.json()
      return response
    } else {
      throw new Error(fetchResponse.statusText)
    }
  } catch (e) {
    throw new Error('Failed create user for email: ' + user.email, { cause: e as Error })
  }
}
