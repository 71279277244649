import 'firebaseui/dist/firebaseui.css'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { AnalyticsConfiguration } from './pages/AnalyticsConfiguration'
import { AppFrame } from './common/components/organisms/AppFrame'
import { AuthContext } from './common/components/atoms/AuthContext'
import { AuthProvider } from './common/components/atoms/AuthProvider'
import { BigqueryConfiguration } from './pages/BigqueryConfiguration'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CircularProgress, CssBaseline, ThemeProvider } from '@mui/material'
import { ConfigurationDone } from './pages/ConfigurationDone'
import { DateTime } from 'luxon'
import { GrantPermissionAnalytics } from './pages/GrantPermissionAnalytics'
import { GrantPermissionBigquery } from './pages/GrantPermissionBigquery'
import { Loading } from './pages/Loading'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { Login } from './pages/Login'
import { ModelConfiguration } from './pages/ModelConfiguration'
import { ModelConfigurationDone } from './pages/ModelConfigurationDone'
import { ModelTypeConfiguration } from './pages/ModelTypeConfiguration'
import { PageRouter } from './pages/PageRouter'
import { PropsWithChildren, StrictMode, Suspense, useContext } from 'react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Settings } from './pages/Settings'
import { TrainedModelDashboard } from './pages/TrainedModelDashboard'
import { auth } from './common/config/firebaseSetup'
import { queryClient, useQueryCacheReady } from './common/query/query-client'
import { useTheme } from './common/theme/use-theme'

const AppRouter = () => {
  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null
  const isLoaded = context?.isLoaded

  return (
    <Suspense fallback={<CircularProgress />}>
      <Routes>
        {firebaseUser ? (
          <>
            <Route path="grant-permission-bigquery" element={<GrantPermissionBigquery />} />
            <Route path="grant-permission-analytics" element={<GrantPermissionAnalytics />} />
            <Route path="bigquery-configuration" element={<BigqueryConfiguration />} />
            <Route path="analytics-configuration" element={<AnalyticsConfiguration />} />
            <Route path="configuration-done" element={<ConfigurationDone />} />
            <Route path="model-configuration-done" element={<ModelConfigurationDone />} />
            <Route path="trained-model-dashboard" element={<TrainedModelDashboard />} />
            <Route path="model-type-configuration" element={<ModelTypeConfiguration />} />
            <Route path="model-configuration/:configId" element={<ModelConfiguration />} />
            <Route path="page-router" element={<PageRouter />} />
            <Route path="settings" element={<Settings />} />
            <Route path="*" element={<PageRouter />} />
          </>
        ) : (
          <>
            {!firebaseUser && isLoaded && <Route path="*" element={<Login auth={auth} />} />}
            {!firebaseUser && !isLoaded && <Route path="*" element={<Loading />} />}
          </>
        )}
      </Routes>
    </Suspense>
  )
}

const MaterialUiContext = (props: PropsWithChildren<unknown>) => {
  const theme = useTheme()
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={DateTime.now().locale}>
          {props.children}
        </LocalizationProvider>
      </ThemeProvider>
    </>
  )
}

const AppContext = (props: PropsWithChildren<unknown>) => (
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      {props.children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  </BrowserRouter>
)

export const App = () => {
  const cacheReady = useQueryCacheReady()
  if (!cacheReady) {
    return null
  }

  return (
    <StrictMode>
      <AuthProvider>
        <AppContext>
          <MaterialUiContext>
            <AppFrame>
              <AppRouter />
            </AppFrame>
          </MaterialUiContext>
        </AppContext>
      </AuthProvider>
    </StrictMode>
  )
}
