import { AppMenu } from '../common/components/atoms/AppMenu'
import { AuthContext } from '../common/components/atoms/AuthContext'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { LocalHeader } from '../common/components/molecules/LocalHeader'
import { ProgressBarProps } from '../common/components/atoms/ProgressBar'
import { User } from '../common/data/User'
import { getCurrentUser } from '../common/query/current-user'
import { useContext, useEffect, useState } from 'react'
import { useNavigateToCorrectPath } from '../common/hooks/use-navigate'

export const ConfigurationDone = () => {
  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null
  const [currentUser, setCurrentUser] = useState<User>()

  useEffect(() => {
    getCurrentUser(firebaseUser).then((data) => setCurrentUser(data))
  }, [firebaseUser])

  useNavigateToCorrectPath(currentUser, '/trained-model-dashboard')

  const tabList = ['Configuration']

  const progressBarProps: ProgressBarProps = {
    numberOfSteps: 4,
    stepsCompleted: 4,
  }

  return (
    <>
      {firebaseUser && (
        <>
          <AppMenu tabs={tabList} activeTab={tabList.indexOf('Configuration')} />
          <Box
            sx={{
              bgcolor: 'background.paper',
              maxWidth: '1080px',
              width: '100%',
              margin: 'auto',
              borderRadius: 1,
              p: 3,
            }}
          >
            <LocalHeader progressBar={progressBarProps} title="You're almost set!" />
            <Typography sx={{ m: '15px 0px' }} variant="body1">
              Define your business objective and start training your models.
            </Typography>
            <Stack
              flexDirection="row"
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                mt: '24px',
              }}
            >
              <Button component={Link} to="/trained-model-dashboard" variant="contained" color="error">
                Proceed to Models
              </Button>
            </Stack>
          </Box>
        </>
      )}
    </>
  )
}
