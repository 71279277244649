import { AppMenu } from '../common/components/atoms/AppMenu'
import { AuthContext } from '../common/components/atoms/AuthContext'
import { Box, Button, Link as MuiLink, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { LocalHeader } from '../common/components/molecules/LocalHeader'
import { ProgressBarProps } from '../common/components/atoms/ProgressBar'
import { User } from '../common/data/User'
import { getCurrentUser } from '../common/query/current-user'
import { useContext, useEffect, useState } from 'react'
import { useNavigateToCorrectPath } from '../common/hooks/use-navigate'

export const ModelConfigurationDone = () => {
  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null
  const [currentUser, setCurrentUser] = useState<User>()

  useEffect(() => {
    getCurrentUser(firebaseUser).then((data) => setCurrentUser(data))
  }, [firebaseUser])

  useNavigateToCorrectPath(currentUser, '/trained-model-dashboard')

  const tabList = ['Models']

  const progressBarProps: ProgressBarProps = {
    numberOfSteps: 2,
    stepsCompleted: 2,
  }

  return (
    <>
      {firebaseUser && (
        <>
          <AppMenu tabs={tabList} activeTab={tabList.indexOf('Models')} />
          <Box
            sx={{
              bgcolor: 'background.paper',
              maxWidth: '1080px',
              width: '100%',
              margin: 'auto',
              borderRadius: 1,
              p: 3,
              color: 'secondary.light',
            }}
          >
            <LocalHeader progressBar={progressBarProps} title="Successfully configured!" />
            <Typography sx={{ m: '15px 0px' }} variant="body1">
              Your model now is training. You can check the status in the Models page.
            </Typography>
            <Typography sx={{ m: '15px 0px' }} variant="body1">
              Approximately 30-60 minutes after the model is <strong>In operation</strong> state, you should see the
              custom dimension you provided previously populated with data in Google Analytics.
            </Typography>
            <Typography sx={{ m: '15px 0px' }} variant="body1">
              The next step to optimize your campaign is to{' '}
              <MuiLink
                color="secondary.light"
                href="https://support.google.com/analytics/answer/2611404#zippy=%2Cin-this-article"
                variant="body1"
                rel="noopener"
                target="_blank"
              >
                create a new audience
              </MuiLink>
              . You need to use the Advanced Conditions in the audience builder and use the model output custom
              dimension.
            </Typography>
            <Typography sx={{ m: '15px 0px' }} variant="body1">
              Model scores range from 0-1000 where 1000 represents the most probable customers. An audience filter can
              be created by using regular expression that selects users above 800 probability score:{' '}
              <strong>^[89]\d{2}*</strong> . This means values starting with 8 or 9 and being 3 digits long followed by
              any character.
            </Typography>
            <Typography sx={{ m: '15px 0px' }} variant="body1">
              After you have created the Audience you can start re-targeting them in Google Ads.
            </Typography>
            <Stack
              flexDirection="row"
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                mt: '24px',
              }}
            >
              <Button component={Link} to="/trained-model-dashboard" variant="contained" color="error">
                Proceed to Models
              </Button>
            </Stack>
          </Box>
        </>
      )}
    </>
  )
}
