import { AuthContext } from '../common/components/atoms/AuthContext'
import { GrantPermission } from '../common/components/organisms/GrantPermission'
import { ProgressBarProps } from '../common/components/atoms/ProgressBar'
import { Typography } from '@mui/material'
import { User } from '../common/data/User'
import { getCurrentUser } from '../common/query/current-user'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigateToCorrectPath } from '../common/hooks/use-navigate'
import editorRoleImage from '../../assets/analytics_editor-role.png'
import viewAccessManagementImage from '../../assets/analytics_view-access-management.png'

export const GrantPermissionAnalytics = () => {
  const viewAccessManagement = new URL(viewAccessManagementImage, import.meta.url)
  const editorRole = new URL(editorRoleImage, import.meta.url)

  const [currentUser, setCurrentUser] = useState<User>()
  const location = useLocation()

  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null

  useEffect(() => {
    getCurrentUser(firebaseUser).then((data) => setCurrentUser(data))
  }, [firebaseUser])

  useNavigateToCorrectPath(currentUser, location.pathname)

  const grantPermissionItems = [
    {
      key: '1',
      text: '1. In Property Access management click + sign',
      url: viewAccessManagement,
      title: 'In Property Access management click + sign',
    },
    {
      key: '2',
      text: '2. Add Editor role',
      url: editorRole,
      title: 'Add Editor role',
    },
  ]

  const progressBarProps: ProgressBarProps = {
    numberOfSteps: 4,
    stepsCompleted: 2,
  }

  const description = (
    <>
      <Typography color="secondary.dark" sx={{ m: '15px 0px' }} variant="body1">
        Target audience suggestions are exported back to your Google Analytics account therefore we need to have{' '}
        <strong>
          <em>Edit</em>
        </strong>{' '}
        access to your account.
      </Typography>
      <Typography color="secondary.dark" sx={{ m: '15px 0px' }} variant="body1">
        We’ve created a the following email which for you have to grant access.
      </Typography>
    </>
  )

  return (
    <GrantPermission
      items={grantPermissionItems}
      progressBarProps={progressBarProps}
      targetUrl="/analytics-configuration"
      serviceAccountEmail={currentUser?.serviceAccountEmail}
      description={description}
    />
  )
}
