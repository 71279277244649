import { Model } from '../data/Model'
import { User } from '../data/User'

const userStatuses = ['unregistered', 'bigquery', 'analytics', 'trained-models', 'model-config', 'model-ready'] as const
export type UserStatus = (typeof userStatuses)[number]

const modelStatuses = ['type-selected', 'finished'] as const
export type ModelStatus = (typeof modelStatuses)[number]

export const getConfigStatusOfUser = (user: User | undefined): UserStatus => {
  if (user === undefined) {
    return 'unregistered'
  }

  if (
    user.googleAnalyticsAccount?.accountId &&
    user.googleAnalyticsAccount?.propertyId &&
    user.googleAnalyticsAccount?.trackingType &&
    user.googleAnalyticsAccount?.joinKeyScope
  ) {
    return 'trained-models'
  }
  if (
    user.googleAnalyticsExport?.datasetId &&
    user.googleAnalyticsExport?.location &&
    user.googleAnalyticsExport?.projectId
  ) {
    return 'analytics'
  }
  if (user.userEmail) {
    return 'bigquery'
  } else {
    return 'unregistered'
  }
}

export const getConfigStatusOfModel = (model: Model | undefined): ModelStatus => {
  if (model === undefined) {
    return 'finished'
  }
  if (model.gaCustomDimensionId && model.gaImportDatasetId && model.provisionStatus) {
    return 'finished'
  }
  if (model.modelType && model.modelName) {
    return 'type-selected'
  } else {
    return 'finished'
  }
}

export const getCorrectPathOfUser = (user: User): string => {
  const status = getConfigStatusOfUser(user)

  switch (status) {
    case 'unregistered':
      return '/login'
    case 'bigquery':
      return '/grant-permission-bigquery'
    case 'analytics':
      return '/grant-permission-analytics'
    case 'trained-models':
      return '/trained-model-dashboard'
    default:
      return '/login'
  }
}

export const getCorrectPathOfModel = (model: Model): string => {
  const status = getConfigStatusOfModel(model)

  switch (status) {
    case 'type-selected':
      return '/model-configuration'
    case 'finished':
      return '/trained-model-dashboard'
    default:
      return '/login'
  }
}
