import { AppMenu } from '../atoms/AppMenu'
import { AuthContext } from '../atoms/AuthContext'
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import { GrantPermissionStep, GrantPermissionStepProps } from '../molecules/GrantPermissionStep'
import { Link } from 'react-router-dom'
import { LocalHeader } from '../molecules/LocalHeader'
import { ProgressBarProps } from '../atoms/ProgressBar'
import { useContext, useState } from 'react'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'

const Footer = (props: { checked; setChecked; targetUrl }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        mt: '24px',
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            color="error"
            value={props.checked}
            onChange={() => {
              props.setChecked(!props.checked)
            }}
          />
        }
        label="I’ve granted the permission"
      />
      <Button component={Link} to={props.targetUrl} variant="contained" color="error" disabled={!props.checked}>
        Proceed Further
      </Button>
    </Box>
  )
}

export const GrantPermission = (props: {
  items: GrantPermissionStepProps[]
  progressBarProps: ProgressBarProps
  targetUrl: string
  serviceAccountEmail: string | undefined
  description: JSX.Element
}) => {
  const [checked, setChecked] = useState(false)
  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null

  const tabList = ['Configuration']

  const steps = props.items.map((item, i) => {
    return <GrantPermissionStep key={i} {...item} />
  })

  return (
    <>
      {firebaseUser && (
        <>
          <AppMenu tabs={tabList} activeTab={tabList.indexOf('Configuration')} />
          <Box
            sx={{
              bgcolor: 'background.paper',
              maxWidth: '1080px',
              width: '100%',
              margin: 'auto',
              borderRadius: 1,
              p: 3,
            }}
          >
            <LocalHeader progressBar={props.progressBarProps} title="Let’s boost your campaigns!" />
            <Box margin="auto" maxWidth="1080px">
              <Grid container justifyContent="center" alignItems="center" columns={{ xs: 1, md: 2 }} direction="row">
                <Grid xs={1} item>
                  <Typography variant="h6">
                    Permission set-up required{' '}
                    <span>
                      <WarningRoundedIcon />
                    </span>
                  </Typography>
                  {props.description}
                  {props.serviceAccountEmail && (
                    <TextField
                      id="outlined-basic"
                      label="Permission email"
                      variant="outlined"
                      fullWidth={true}
                      value={props.serviceAccountEmail}
                      focused
                    />
                  )}
                  {!props.serviceAccountEmail && <CircularProgress />}
                  <Typography variant="h6" sx={{ pt: 2 }}>
                    For granting permission please follow these steps:
                  </Typography>
                  {steps}
                </Grid>
                <Grid sx={{ pl: '12px' }} item xs={1}></Grid>
              </Grid>
            </Box>
            <Footer checked={checked} setChecked={setChecked} targetUrl={props.targetUrl} />
          </Box>
        </>
      )}
    </>
  )
}
