import * as Yup from 'yup'
import { AppMenu } from '../common/components/atoms/AppMenu'
import { AuthContext } from '../common/components/atoms/AuthContext'
import { Box, Button, FormControl, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { ImageContainer } from '../common/theme/ImageContainer'
import { Link, useNavigate } from 'react-router-dom'
import { LocalHeader } from '../common/components/molecules/LocalHeader'
import { ProgressBarProps } from '../common/components/atoms/ProgressBar'
import { QueryError, ValidationStatus } from './BigqueryConfiguration'
import { User } from '../common/data/User'
import { ValidationCircularSpinner } from '../common/components/atoms/ValidationCircularSpinner'
import { getCurrentUser } from '../common/query/current-user'
import { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useKeyScopes } from '../common/hooks/queries/use-key-scopes'
import { useNavigateToCorrectPath } from '../common/hooks/use-navigate'
import { useTrackingTypes } from '../common/hooks/queries/use-tracking-types'
import HelperModal from '../common/components/atoms/HelperModal'

export const AnalyticsConfiguration = () => {
  const [currentUser, setCurrentUser] = useState<User>()

  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null

  useEffect(() => {
    getCurrentUser(firebaseUser).then((data) => setCurrentUser(data))
  }, [firebaseUser])

  useNavigateToCorrectPath(currentUser, '/grant-permission-analytics')
  const navigate = useNavigate()

  const [status, setStatus] = useState<ValidationStatus>('initial')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const trackingTypesQuery = useTrackingTypes(firebaseUser)
  const keyScopesQuery = useKeyScopes(firebaseUser)

  const trackingTypeObjects = trackingTypesQuery.data ?? []
  const trackingTypeMap = new Map(Object.entries(trackingTypeObjects))

  const trackingTypeKeys = Object.entries(trackingTypeObjects).map(([key]) => key)
  type TrackingTypeKey = (typeof trackingTypeKeys)[number]
  const trackingTypeValues = Object.entries(trackingTypeObjects).map(([, value]) => value)
  type TrackingTypeValue = (typeof trackingTypeValues)[number]
  const inputTrackingTypes = Object.entries(trackingTypeObjects).map(([key, value]) => {
    return (
      <MenuItem key={key} value={value}>
        {value}
      </MenuItem>
    )
  })

  const keyScopeObjects = keyScopesQuery.data ?? []
  const keyScopeMap = new Map(Object.entries(keyScopeObjects))

  const keyScopeKeys = Object.entries(keyScopeObjects).map(([key]) => key)
  type KeyScopeKey = (typeof keyScopeKeys)[number]
  const keyScopeValues = Object.entries(keyScopeObjects).map(([, value]) => value)
  type KeyScopeValue = (typeof keyScopeValues)[number]
  const inputKeyScopes = Object.entries(keyScopeObjects).map(([key, value]) => {
    return (
      <MenuItem key={key} value={value}>
        {value}
      </MenuItem>
    )
  })

  interface AnalyticsFormValues {
    accountId: string
    propertyId: string
    trackingType: TrackingTypeValue | ''
    customDimensionIndex: number | ''
    joinKeyScope: KeyScopeValue | ''
  }

  interface AnalyticsSendValues {
    accountId: string
    propertyId: string
    trackingType: TrackingTypeKey
    customDimensionIndex: number | ''
    joinKeyScope: KeyScopeKey
  }

  const tabList = ['Configuration']

  const progressBarProps: ProgressBarProps = {
    numberOfSteps: 4,
    stepsCompleted: 3,
  }

  const textFieldColor = status === 'error' ? 'error' : status === 'success' ? 'success' : 'primary'

  const initialValues: AnalyticsFormValues = {
    accountId: '',
    propertyId: '',
    trackingType: '',
    customDimensionIndex: '',
    joinKeyScope: '',
  }

  const validationSchema = Yup.object({
    accountId: Yup.string().required('Required').matches(/\d{8}/, 'Must be 8 numbers'),
    propertyId: Yup.string()
      .required('Required')
      .matches(/UA-\d{1,}-\d{1,}|G-\w{1,}/, 'Must be like UA-12345-123 or G-ABC123'),
    trackingType: Yup.string()
      .required('Required')
      .oneOf(trackingTypeValues.map((type) => type)),
    customDimensionIndex: Yup.number().positive('Must be positive').integer('Must be an integer'),
    joinKeyScope: Yup.string()
      .required('Required')
      .oneOf(keyScopeValues.map((scope) => scope)),
  })

  const onSubmit = async (values: AnalyticsFormValues) => {
    if (status === 'success') {
      navigate('/configuration-done')
    } else {
      const fetchResponse: User | QueryError = await sendForm(values)
      setLoading(false)

      if (Object.hasOwn(fetchResponse, 'userEmail')) {
        setStatus('success')
      } else if (Object.hasOwn(fetchResponse, 'message')) {
        setStatus('error')
        setError((fetchResponse as QueryError).message)
      }
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })

  const sendForm = async (analyticsFormValues: AnalyticsFormValues) => {
    if (!firebaseUser) {
      throw new Error('User is not logged in')
    }
    setLoading(true)
    const token = await firebaseUser.getIdToken()
    const analyticsSendValues: AnalyticsSendValues = convert(analyticsFormValues)
    try {
      const url = new URL(`/api/v1/users/analytics`, process.env.API_URL)

      const fetchResponse = await fetch(url.toString(), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(analyticsSendValues),
      })

      return await fetchResponse.json()
    } catch (e) {
      throw new Error('Failed to post analytics data', { cause: e as Error })
    }
  }

  const convert = (values: AnalyticsFormValues): AnalyticsSendValues => {
    return {
      accountId: values.accountId,
      propertyId: values.propertyId,
      trackingType: findKeyForValue(values.trackingType, trackingTypeMap) as TrackingTypeKey,
      customDimensionIndex: values.customDimensionIndex,
      joinKeyScope: findKeyForValue(values.joinKeyScope, keyScopeMap) as KeyScopeKey,
    }
  }

  const findKeyForValue = (searchValue: string, map: Map<string, string>) => {
    for (const [key, value] of map.entries()) {
      if (value === searchValue) {
        return key
      }
    }
  }

  const inputFieldHelper = (url: URL, imageName: string) => {
    return {
      endAdornment: (
        <InputAdornment position="end">
          <HelperModal url={url} imageName={imageName} />
        </InputAdornment>
      ),
    }
  }

  const inputFieldHelperWithTextOnly = (text: string) => {
    return {
      endAdornment: (
        <InputAdornment position="end">
          <HelperModal description={text} />
        </InputAdornment>
      ),
    }
  }

  return (
    <>
      {firebaseUser && (
        <>
          <AppMenu tabs={tabList} activeTab={tabList.indexOf('Configuration')} />
          <Box
            sx={{
              bgcolor: 'background.paper',
              maxWidth: '1080px',
              width: '100%',
              margin: 'auto',
              borderRadius: 1,
              p: 3,
            }}
          >
            <LocalHeader progressBar={progressBarProps} title="Let’s boost your campaigns!" />
            <Box margin="auto" maxWidth="1080px">
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  color={textFieldColor}
                  focused={status === 'success' ? true : false}
                  sx={{ mb: 2 }}
                  fullWidth
                  placeholder='e.g. "12345678"'
                  id="accountId"
                  name="accountId"
                  label="Google Analytics Account ID *"
                  value={formik.values.accountId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.accountId && Boolean(formik.errors.accountId)}
                  helperText={formik.touched.accountId && formik.errors.accountId}
                  disabled={loading}
                  InputProps={inputFieldHelper(ImageContainer.accountId, 'Account id')}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  color={textFieldColor}
                  focused={status === 'success' ? true : false}
                  sx={{ mb: 2 }}
                  fullWidth
                  placeholder='e.g. "UA-12345678-1"'
                  id="propertyId"
                  name="propertyId"
                  label="Google Analytics Property ID *"
                  value={formik.values.propertyId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.propertyId && Boolean(formik.errors.propertyId)}
                  helperText={formik.touched.propertyId && formik.errors.propertyId}
                  disabled={loading}
                  InputProps={inputFieldHelper(ImageContainer.propertyId, 'Property id')}
                />

                <FormControl fullWidth>
                  <TextField
                    color={textFieldColor}
                    focused={status === 'success' ? true : false}
                    sx={{ mb: 2 }}
                    select
                    name="trackingType"
                    id="trackingType"
                    value={formik.values.trackingType}
                    label="User ID tracking type *"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.trackingType && Boolean(formik.errors.trackingType)}
                    helperText={formik.touched.trackingType && formik.errors.trackingType}
                    disabled={loading}
                  >
                    {inputTrackingTypes}
                  </TextField>
                </FormControl>

                <TextField
                  InputLabelProps={{ shrink: true }}
                  color={textFieldColor}
                  focused={status === 'success' ? true : false}
                  sx={{ mb: 2 }}
                  fullWidth
                  placeholder='e.g. "1"'
                  id="customDimensionIndex"
                  name="customDimensionIndex"
                  label="User ID Custom dimension index"
                  value={formik.values.customDimensionIndex}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.customDimensionIndex && Boolean(formik.errors.customDimensionIndex)}
                  helperText={formik.touched.customDimensionIndex && formik.errors.customDimensionIndex}
                  disabled={loading}
                  InputProps={inputFieldHelperWithTextOnly('The index of the Custom Dimension storing the clientID.')}
                />

                <FormControl fullWidth>
                  <TextField
                    color={textFieldColor}
                    focused={status === 'success' ? true : false}
                    sx={{ mb: 2 }}
                    select
                    name="joinKeyScope"
                    id="joinKeyScope"
                    label="Join Key Scope *"
                    value={formik.values.joinKeyScope}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.joinKeyScope && Boolean(formik.errors.joinKeyScope)}
                    helperText={formik.touched.joinKeyScope && formik.errors.joinKeyScope}
                    disabled={loading}
                    InputProps={inputFieldHelperWithTextOnly(
                      'e.g. If the clientID is stored for each Hit in the custom dimensions, then selection should be Hit.'
                    )}
                  >
                    {inputKeyScopes}
                  </TextField>
                </FormControl>

                <Stack
                  flexDirection="row"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: '24px',
                  }}
                >
                  <Button component={Link} to="/grant-permission-analytics" variant="contained" color="secondary">
                    Back
                  </Button>

                  {!loading && status === 'error' && (
                    <Typography variant="body2" sx={{ ml: 2, mr: 2, color: 'error.main' }}>
                      {error}! Have you granted all required accesses? If not, please go back to previous screen!
                    </Typography>
                  )}
                  {!loading && status === 'success' && (
                    <Typography variant="body2" sx={{ mr: 2, color: 'success.main' }}>
                      Everything looks good!
                    </Typography>
                  )}

                  <Button
                    disabled={!(formik.isValid && formik.dirty) || loading}
                    type="submit"
                    variant="contained"
                    color={status === 'success' ? 'success' : 'error'}
                  >
                    {!loading && status === 'initial' && <span>Check</span>}
                    {loading && (
                      <span>
                        <ValidationCircularSpinner /> {'Validating'}
                      </span>
                    )}
                    {!loading && status === 'error' && <span>Check Again</span>}
                    {!loading && status === 'success' && <span>Proceed Further</span>}
                  </Button>
                </Stack>
              </form>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}
