import { Typography } from '@mui/material'
import HelperModal from '../atoms/HelperModal'

export interface GrantPermissionStepProps {
  text: string
  url: URL
  title: string
}

export const GrantPermissionStep = (props: GrantPermissionStepProps) => {
  return (
    <Typography display="flex" sx={{ m: '15px 0px', justifyContent: 'space-between' }} variant="body1">
      {props.text}
      <HelperModal url={props.url} imageName={props.text} title={props.title} />
    </Typography>
  )
}
