import { AppMenu } from '../common/components/atoms/AppMenu'
import { AuthContext } from '../common/components/atoms/AuthContext'
import { Box, Button, CircularProgress, Link as MuiLink, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { LocalHeader } from '../common/components/molecules/LocalHeader'
import { MAX_ALLOWED_MODELS } from '../common/config/modelConfig'
import { ModelTable } from '../common/components/molecules/ModelTable'
import { User } from '../common/data/User'
import { getCurrentUser } from '../common/query/current-user'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigateToCorrectPath } from '../common/hooks/use-navigate'
import AddIcon from '@mui/icons-material/Add'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

export const TrainedModelDashboard = () => {
  const [currentUser, setCurrentUser] = useState<User>()
  const [counter, setCounter] = useState(1)
  const location = useLocation()

  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null

  useEffect(() => {
    getCurrentUser(firebaseUser).then((data) => {
      setCurrentUser(data)
    })
  }, [firebaseUser, counter])

  const REFRESH_TIME = 60 * 1000

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter + 1)
    }, REFRESH_TIME)

    return () => {
      clearInterval(interval)
    }
  }, [REFRESH_TIME, counter, currentUser?.models])

  useNavigateToCorrectPath(currentUser, location.pathname)
  const tabList = ['Models', 'Settings']
  const tabUrlList = ['/trained-model-dashboard', '/settings']

  const isEmpty = !currentUser?.models?.length

  const clickHandler = () => {
    getCurrentUser(firebaseUser).then((data) => {
      setCurrentUser(data)
    })
  }

  return (
    <>
      {firebaseUser && (
        <>
          <AppMenu tabs={tabList} activeTab={tabList.indexOf('Models')} tabUrls={tabUrlList} />
          <Box
            sx={{
              bgcolor: 'background.paper',
              maxWidth: '1080px',
              width: '100%',
              margin: 'auto',
              borderRadius: 1,
              p: 3,
            }}
          >
            <LocalHeader title="Your trained models" />
            {!currentUser && <CircularProgress />}
            {currentUser && !isEmpty && (
              <Stack sx={{ mb: 3 }}>
                <ModelTable clickHandler={clickHandler} currentUser={currentUser} />
              </Stack>
            )}
            {currentUser && isEmpty && (
              <>
                <Typography sx={{ m: '15px 0px' }} variant="body1">
                  You do not have any models set up. Click the button below to start the setup.
                </Typography>
              </>
            )}

            <Stack sx={{ mb: 3 }} flexDirection="row">
              <Button
                disabled={!!currentUser?.models?.length && currentUser?.models?.length >= MAX_ALLOWED_MODELS}
                component={Link}
                to="/model-type-configuration"
                variant="outlined"
                color="error"
              >
                <AddIcon />
                Add New Model
              </Button>

              {currentUser?.models && currentUser?.models?.length >= MAX_ALLOWED_MODELS && (
                <Stack
                  flexDirection="row"
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'start',
                    p: 1,
                    ml: 3,
                    backgroundColor: '#FBEAEA',
                    borderRadius: 1,
                  }}
                >
                  <ErrorOutlineIcon sx={{ color: '#D32F2F' }} />
                  <Typography sx={{ pl: 1 }} variant="body2" color="#541313">
                    You reached the maximum number of models: 5. Please contact us for{' '}
                    <strong>Enterprise Package</strong> at:{' '}
                    <MuiLink
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={() => (window.location.href = 'mailto:ml@aliz.ai')}
                    >
                      ml@aliz.ai
                    </MuiLink>
                  </Typography>
                </Stack>
              )}
            </Stack>

            <Stack
              flexDirection="column"
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'start',
                p: '14px',
                backgroundColor: '#d3dfd9',
                borderRadius: 1,
              }}
            >
              <Stack
                flexDirection="row"
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'start',
                }}
              >
                <ErrorOutlineIcon sx={{ color: 'secondary.light' }} />
                <Typography sx={{ pl: '12px' }} variant="body1" color="secondary.light">
                  Note
                </Typography>
              </Stack>

              <Typography sx={{ pl: '36px' }} variant="body2" color="secondary.light">
                <strong>How to use model results?</strong>
              </Typography>
              <Typography sx={{ pl: '36px', m: '15px 0px' }} variant="body2" color="secondary.light">
                To optimize your campaign you need to create a new{' '}
                <MuiLink
                  color="secondary.light"
                  href="https://support.google.com/analytics/answer/2611404#zippy=%2Cin-this-article"
                  variant="body1"
                  rel="noopener"
                  target="_blank"
                >
                  audience
                </MuiLink>{' '}
                . The Advanced Conditions option shall be used in the audience builder with the model output custom
                dimension selected.
              </Typography>
              <Typography sx={{ pl: '36px', m: '15px 0px' }} variant="body2" color="secondary.light">
                Model scores range from 0-1000 where 1000 represents the most probable customers. An audience filter can
                be created by using regular expression that selects users above 800 probability score:{' '}
                <strong>^[89]\d{2}*</strong> . This means values starting with 8 or 9 and being 3 digits long followed
                by any character.
              </Typography>
              <Typography sx={{ pl: '36px', m: '15px 0px' }} variant="body2" color="secondary.light">
                After you have created the Audience you can start re-targeting them in Google Ads.
              </Typography>
            </Stack>
          </Box>
        </>
      )}
    </>
  )
}
