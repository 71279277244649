import { User, findModel } from '../data/User'
import { getCorrectPathOfModel, getCorrectPathOfUser } from '../query/status-router'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const useNavigateToCorrectPath = (currentUser: User | undefined, currentPath: string, configId?: string) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser !== undefined) {
      const correctUserPath = getCorrectPathOfUser(currentUser)
      if (configId) {
        const model = findModel(currentUser, configId)

        if (!model) {
          navigate(correctUserPath)
        } else {
          const correctModelPath = getCorrectPathOfModel(model)
          if (
            process.env.FREE_NAVIGATION_ALLOWED === 'false' &&
            (correctUserPath !== '/trained-model-dashboard' || correctModelPath !== currentPath)
          ) {
            navigate(correctUserPath)
          }
        }
      } else {
        if (process.env.FREE_NAVIGATION_ALLOWED === 'false' && correctUserPath !== currentPath) {
          navigate(correctUserPath)
        }
      }
    }
  }),
    [currentUser, currentPath, navigate]
}
