import { Box, LinearProgress, Stack, Typography } from '@mui/material'

export interface ProgressBarProps {
  numberOfSteps: number
  stepsCompleted: number
}

export const ProgressBar = (props: ProgressBarProps) => {
  const progress = (props.stepsCompleted / props.numberOfSteps) * 100
  return (
    <Stack flexDirection="row" sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
      <Typography variant="caption" color="secondary.dark">
        {props.stepsCompleted} / {props.numberOfSteps} completed
      </Typography>
      <Box sx={{ width: '50%' }}>
        <LinearProgress sx={{ ml: '20px' }} color="success" value={progress} variant="determinate" />
      </Box>
    </Stack>
  )
}
