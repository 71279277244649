import { AppMenu } from '../common/components/atoms/AppMenu'
import { AuthContext } from '../common/components/atoms/AuthContext'
import { Box, CircularProgress, Link, Stack, TextField, Typography } from '@mui/material'
import { User } from '../common/data/User'
import { getCorrectPathOfUser } from '../common/query/status-router'
import { getCurrentUser } from '../common/query/current-user'
import { useContext, useEffect, useState } from 'react'
import { useNavigateToCorrectPath } from '../common/hooks/use-navigate'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

export const Settings = () => {
  const tabList = ['Models', 'Settings']

  const [currentUser, setCurrentUser] = useState<User>()

  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null

  useEffect(() => {
    getCurrentUser(firebaseUser).then((data) => setCurrentUser(data))
  }, [firebaseUser])

  const correctPath = currentUser ? getCorrectPathOfUser(currentUser) : '/settings'
  const callerPath = correctPath === '/trained-model-dashboard' ? correctPath : '/settings'
  useNavigateToCorrectPath(currentUser, callerPath)
  const tabUrlList = [correctPath, '/settings']

  return (
    <>
      {firebaseUser && (
        <>
          <AppMenu tabs={tabList} activeTab={tabList.indexOf('Settings')} tabUrls={tabUrlList} />
          {!currentUser && <CircularProgress />}
          {currentUser && (
            <Box
              sx={{
                bgcolor: 'background.paper',
                maxWidth: '1080px',
                width: '100%',
                margin: 'auto',
                borderRadius: 1,
                p: 3,
              }}
            >
              <Typography variant="h5" color="primary">
                Google Cloud Settings
              </Typography>
              <Stack
                flexDirection="column"
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'left',
                  mt: '24px',
                }}
              >
                <TextField
                  focused
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Google Cloud Project ID"
                  value={currentUser?.googleAnalyticsExport?.projectId || ''}
                  disabled
                />

                <TextField
                  focused
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Dataset ID"
                  value={currentUser?.googleAnalyticsExport?.datasetId || ''}
                  disabled
                />

                <TextField
                  focused
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Dataset Location"
                  value={currentUser?.googleAnalyticsExport?.location || ''}
                  disabled
                />
              </Stack>

              <Typography sx={{ pt: 2 }} variant="h5" color="primary">
                Google Analytics Settings
              </Typography>
              <Stack
                flexDirection="column"
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  mt: '24px',
                }}
              >
                <TextField
                  focused
                  sx={{ mb: 2 }}
                  fullWidth
                  label="GA Account ID"
                  value={currentUser?.googleAnalyticsAccount?.accountId || ''}
                  disabled
                />

                <TextField
                  focused
                  sx={{ mb: 2 }}
                  fullWidth
                  label="GA Property ID"
                  value={currentUser?.googleAnalyticsAccount?.propertyId || ''}
                  disabled
                />

                <TextField
                  focused
                  sx={{ mb: 2 }}
                  fullWidth
                  label="User ID Custom dimension index"
                  value={currentUser?.googleAnalyticsAccount?.customDimensionIndex || ''}
                  disabled
                />

                <TextField
                  focused
                  sx={{ mb: 2 }}
                  fullWidth
                  label="User ID tracking type"
                  value={currentUser?.googleAnalyticsAccount?.trackingType || ''}
                  disabled
                />

                <TextField
                  focused
                  sx={{ mb: 2 }}
                  fullWidth
                  label="Join Key Scope"
                  value={currentUser?.googleAnalyticsAccount?.joinKeyScope || ''}
                  disabled
                />
              </Stack>

              <Stack
                flexDirection="column"
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'start',
                  p: '14px 14px',
                  backgroundColor: '#FBEAEA',
                  borderRadius: 1,
                }}
              >
                <Stack
                  flexDirection="row"
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'start',
                    backgroundColor: '#FBEAEA',
                  }}
                >
                  <ErrorOutlineIcon sx={{ color: '#D32F2F' }} />
                  <Typography sx={{ pl: '12px' }} variant="body1" color="#541313">
                    Note
                  </Typography>
                </Stack>
                <Typography sx={{ pl: '36px' }} variant="body2" color="#541313">
                  Your input has already been saved. If you think you need to change any of them, please contact us at:{' '}
                  <Link
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                    onClick={() => (window.location.href = 'mailto:ml@aliz.ai')}
                  >
                    ml@aliz.ai
                  </Link>
                </Typography>
              </Stack>
            </Box>
          )}
        </>
      )}
    </>
  )
}
