import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'

interface ModelTypeCardProps {
  title: string
  description: string
  imagePath: string
  isSelected: boolean
  isComingSoon?: boolean
}

export const ModelTypeCard = (props: ModelTypeCardProps) => {
  return (
    <Card sx={{ width: '328px' }}>
      <div style={{ position: 'relative' }}>
        <CardMedia
          sx={{ opacity: props.isSelected ? 1 : 0.4, height: '130px' }}
          image={props.imagePath}
          title={props.title}
        />
        {props.isComingSoon && (
          <Box
            sx={{
              fontSize: 28,
              position: 'absolute',
              color: 'secondary.dark',
              top: 40,
              left: '50%',
              transform: 'translateX(-50%) rotate(-30deg)',
            }}
          >
            {' '}
            Coming Soon
          </Box>
        )}
      </div>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography variant="body1">{props.title}</Typography>
        <Typography variant="body2" color="textSecondary">
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  )
}
