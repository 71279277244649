import * as Yup from 'yup'
import { AppMenu } from '../common/components/atoms/AppMenu'
import { AuthContext } from '../common/components/atoms/AuthContext'
import { Box, Button, FormControl, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { ImageContainer } from '../common/theme/ImageContainer'
import { Link, useNavigate } from 'react-router-dom'
import { LocalHeader } from '../common/components/molecules/LocalHeader'
import { ProgressBarProps } from '../common/components/atoms/ProgressBar'
import { User } from '../common/data/User'
import { ValidationCircularSpinner } from '../common/components/atoms/ValidationCircularSpinner'
import { getCurrentUser } from '../common/query/current-user'
import { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useNavigateToCorrectPath } from '../common/hooks/use-navigate'
import { useRegions } from '../common/hooks/queries/use-regions'
import HelperModal from '../common/components/atoms/HelperModal'

export interface QueryError {
  errorCode: string
  message: string
  timestamp: string
}

export const validationStatuses = ['initial', 'validating', 'error', 'success'] as const

export type ValidationStatus = (typeof validationStatuses)[number]

export const BigqueryConfiguration = () => {
  const [currentUser, setCurrentUser] = useState<User>()

  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null

  useEffect(() => {
    getCurrentUser(firebaseUser).then((data) => setCurrentUser(data))
  }, [firebaseUser])

  useNavigateToCorrectPath(currentUser, '/grant-permission-bigquery')
  const navigate = useNavigate()

  const [status, setStatus] = useState<ValidationStatus>('initial')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const regionsQuery = useRegions(firebaseUser)
  const regionObjects = regionsQuery.data ?? []
  const regionMap = new Map(Object.entries(regionObjects))

  const regionKeys = Object.entries(regionObjects).map(([key]) => key)
  type RegionKey = (typeof regionKeys)[number]
  const regionValues = Object.entries(regionObjects).map(([, value]) => value)
  type RegionValue = (typeof regionValues)[number]
  const regionTypes = regionKeys.sort().map((key) => {
    return (
      <MenuItem key={key} value={regionMap.get(key)}>
        {regionMap.get(key)}
      </MenuItem>
    )
  })

  interface BigqueryFormValues {
    projectId: string
    datasetId: string
    location: RegionValue | ''
  }

  interface BigquerySendValues {
    projectId: string
    datasetId: string
    location: RegionKey | ''
  }

  const tabList = ['Configuration']

  const progressBarProps: ProgressBarProps = {
    numberOfSteps: 4,
    stepsCompleted: 1,
  }

  const textFieldColor = status === 'error' ? 'error' : status === 'success' ? 'success' : 'primary'

  const initialValues: BigqueryFormValues = {
    projectId: '',
    datasetId: '',
    location: '',
  }

  const validationSchema = Yup.object({
    projectId: Yup.string().required('Required'),
    datasetId: Yup.string().required('Required'),
    location: Yup.string()
      .required('Required')
      .oneOf(regionValues.map((scope) => scope)),
  })

  const onSubmit = async (values: BigqueryFormValues) => {
    if (status === 'success') {
      navigate('/grant-permission-analytics')
    } else {
      const fetchResponse: User | QueryError = await sendForm(values)
      setLoading(false)

      if (Object.hasOwn(fetchResponse, 'userEmail')) {
        setStatus('success')
      } else if (Object.hasOwn(fetchResponse, 'message')) {
        setStatus('error')
        setError((fetchResponse as QueryError).message)
      }
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  })

  const sendForm = async (bigqueryFormValues: BigqueryFormValues) => {
    if (!firebaseUser) {
      throw new Error('User is not logged in')
    }
    setLoading(true)
    const token = await firebaseUser.getIdToken()
    const regionsSendValues: BigquerySendValues = convert(bigqueryFormValues)
    try {
      const url = new URL(`/api/v1/users/bigquery`, process.env.API_URL)

      const fetchResponse = await fetch(url.toString(), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(regionsSendValues),
      })

      return await fetchResponse.json()
    } catch (e) {
      throw new Error('Failed to post bigquery data', { cause: e as Error })
    }
  }

  const convert = (values: BigqueryFormValues): BigquerySendValues => {
    return {
      projectId: values.projectId,
      datasetId: values.datasetId,
      location: findKeyForValue(values.location, regionMap) as RegionKey,
    }
  }

  const findKeyForValue = (searchValue: string, map: Map<string, string>) => {
    for (const [key, value] of map.entries()) {
      if (value === searchValue) {
        return key
      }
    }
  }

  const inputFieldHelper = (url: URL, imageName: string) => {
    return {
      endAdornment: (
        <InputAdornment position="end">
          <HelperModal url={url} imageName={imageName} />
        </InputAdornment>
      ),
    }
  }

  return (
    <>
      {firebaseUser && (
        <>
          <AppMenu tabs={tabList} activeTab={tabList.indexOf('Configuration')} />
          <Box
            sx={{
              bgcolor: 'background.paper',
              maxWidth: '1080px',
              width: '100%',
              margin: 'auto',
              borderRadius: 1,
              p: 3,
            }}
          >
            <LocalHeader progressBar={progressBarProps} title="Let’s boost your campaigns!" />
            <Box margin="auto" maxWidth="1080px">
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  color={textFieldColor}
                  focused={status === 'success' ? true : false}
                  sx={{ mb: 2 }}
                  fullWidth
                  placeholder="e.g. my-project-123"
                  id="projectId"
                  name="projectId"
                  label="Google Cloud Project ID for GA data export *"
                  value={formik.values.projectId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.projectId && Boolean(formik.errors.projectId)}
                  helperText={formik.touched.projectId && formik.errors.projectId}
                  disabled={loading}
                  InputProps={inputFieldHelper(ImageContainer.projectId, 'Project id')}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  color={textFieldColor}
                  focused={status === 'success' ? true : false}
                  sx={{ mb: 2 }}
                  fullWidth
                  placeholder="e.g. my_dataset"
                  id="datasetId"
                  name="datasetId"
                  label="BigQuery Dataset ID *"
                  value={formik.values.datasetId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.datasetId && Boolean(formik.errors.datasetId)}
                  helperText={formik.touched.datasetId && formik.errors.datasetId}
                  disabled={loading}
                  InputProps={inputFieldHelper(ImageContainer.bigqueryDatasetId, 'Dataset id')}
                />

                <FormControl fullWidth>
                  <TextField
                    color={textFieldColor}
                    focused={status === 'success' ? true : false}
                    sx={{ mb: 2 }}
                    select
                    name="location"
                    id="location"
                    value={formik.values.location}
                    label="BigQuery Dataset Location *"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.location && Boolean(formik.errors.location)}
                    helperText={formik.touched.location && formik.errors.location}
                    disabled={loading}
                    InputProps={inputFieldHelper(ImageContainer.datasetLocation, 'Dataset location')}
                  >
                    {regionTypes}
                  </TextField>
                </FormControl>

                <Stack
                  flexDirection="row"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: '24px',
                  }}
                >
                  <Button component={Link} to="/grant-permission-bigquery" variant="contained" color="secondary">
                    Back
                  </Button>

                  {!loading && status === 'error' && (
                    <Typography variant="body2" sx={{ ml: 2, mr: 2, color: 'error.main' }}>
                      {error}! Have you granted all required accesses? If not, please go back to previous screen!
                    </Typography>
                  )}
                  {!loading && status === 'success' && (
                    <Typography variant="body2" sx={{ mr: 2, color: 'success.main' }}>
                      Everything looks good!
                    </Typography>
                  )}

                  <Button
                    disabled={!(formik.isValid && formik.dirty) || loading}
                    type="submit"
                    variant="contained"
                    color={status === 'success' ? 'success' : 'error'}
                  >
                    {!loading && status === 'initial' && <span>Check</span>}
                    {loading && (
                      <span>
                        <ValidationCircularSpinner /> {'Validating'}
                      </span>
                    )}
                    {!loading && status === 'error' && <span>Check Again</span>}
                    {!loading && status === 'success' && <span>Proceed Further</span>}
                  </Button>
                </Stack>
              </form>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}
