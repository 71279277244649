import { AccountDropDown } from '../atoms/AccountDropDown'
import { AuthContext } from '../atoms/AuthContext'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { TempPageMenu } from '../atoms/TempPageMenu'
import { useContext } from 'react'

const alizLogoUrl = new URL('../../../../assets/secondarylogo_sacramento_nobg.png', import.meta.url).toString()

const AppHeader = () => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      columns={{ xs: 1, md: 3 }}
      direction="row"
      maxWidth="1080px"
      margin="auto"
      sx={{ pt: '30px', mb: 2 }}
    >
      <Grid item xs={1} md={2}>
        <Typography variant="h4" color="primary">
          Welcome to your Dashboard
        </Typography>
      </Grid>
      <Grid item xs={1} md={1} textAlign="end">
        <AccountDropDown />
      </Grid>
    </Grid>
  )
}

export const AppFooter = () => {
  return <Stack justifyContent="space-between" alignItems="center" direction="row" sx={{ p: 1 }}></Stack>
}

const AlizLogo = () => {
  return (
    <Grid container direction="column" position="absolute" sx={{ display: { xs: 'none', xl: 'flex' } }}>
      <Grid>
        <Box
          component="img"
          sx={{
            display: 'inline',
            width: 'auto',
            height: '3rem',
            m: '30px',
          }}
          src={alizLogoUrl}
        />
      </Grid>
    </Grid>
  )
}

export const AppFrame = (props: React.PropsWithChildren<unknown>) => {
  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null
  return (
    <Stack
      flexDirection="column"
      rowGap={2}
      sx={{
        width: '100hw',
        height: '100vh',
        overflow: 'auto',
        ...(!firebaseUser && { backgroundColor: 'background.paper' }),
      }}
    >
      <>
        <AlizLogo />
        {process.env.FREE_NAVIGATION_ALLOWED === 'true' && <TempPageMenu />}
        {firebaseUser && <AppHeader />}
        <Box sx={{ minWidth: '100%', minHeight: '100%', px: 2, py: 1 }}>{props.children}</Box>
        <AppFooter />
      </>
    </Stack>
  )
}
