import { Button, CardMedia, Grid, Typography } from '@mui/material'
import { URL } from 'url'
import { useState } from 'react'
import Box from '@mui/material/Box'
import InfoIcon from '@mui/icons-material/Info'
import Modal from '@mui/material/Modal'

interface ImageProps {
  url?: URL
  imageName?: string
  title?: string
  description?: string
}

export default function HelperModal(props: ImageProps) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <InfoIcon
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={handleOpen}
      />
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
          }}
        >
          <Typography sx={{ p: '16px 24px' }} id="modal-title" variant="h6" component="h2">
            {props.title}
          </Typography>
          <Typography sx={{ p: '8px 24px' }} id="modal-description" variant="body1">
            {props.description}
          </Typography>
          {props.url && (
            <CardMedia
              sx={{ p: 3 }}
              onClick={handleClose}
              component="img"
              image={props.url.href}
              title={props.imageName}
              alt={props.imageName}
            />
          )}
          <Grid container justifyContent="flex-end">
            <Button sx={{ m: '14px', color: 'error.main' }} onClick={handleClose}>
              Close
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  )
}
