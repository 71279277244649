import { Box, Tab, Tabs } from '@mui/material'
import { Link } from 'react-router-dom'

export interface AppMenuProps {
  tabs: string[]
  activeTab: number
  tabUrls?: string[]
}

export const AppMenu = (props: AppMenuProps) => {
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        maxWidth: '1080px',
        width: '100%',
        margin: 'auto',
        borderRadius: '8px',
        mb: '32px',
        mt: '0px',
      }}
    >
      <Tabs
        value={props.activeTab}
        textColor="primary"
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'error.main',
          },
          '& .MuiTab-root': {
            padding: '9px 24px',
            color: 'secondary',
            fontSize: '14px',
            lineHeight: '24px',
          },
          '& .MuiTab-root.Mui-selected': {
            color: 'error.main',
          },
        }}
      >
        {props.tabs.map((tab, i) => {
          return (
            <Tab
              key={i}
              label={tab}
              component={Link}
              to={props.tabUrls !== undefined ? props.tabUrls[i] : '/'}
              disabled={props.tabUrls === undefined}
            />
          )
        })}
      </Tabs>
    </Box>
  )
}
