import { AuthContext } from '../common/components/atoms/AuthContext'
import { GrantPermission } from '../common/components/organisms/GrantPermission'
import { Link, Typography } from '@mui/material'
import { ProgressBarProps } from '../common/components/atoms/ProgressBar'
import { User } from '../common/data/User'
import { getCurrentUser } from '../common/query/current-user'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigateToCorrectPath } from '../common/hooks/use-navigate'
import addPrincipalImage from '../../assets/add_principal.png'
import bigqueryDataViewerImage from '../../assets/bigquery.png'
import sharingImage from '../../assets/sharing.png'

export const GrantPermissionBigquery = () => {
  const sharing = new URL(sharingImage, import.meta.url)
  const addPrincipal = new URL(addPrincipalImage, import.meta.url)
  const bigQueryDataViewer = new URL(bigqueryDataViewerImage, import.meta.url)

  const [currentUser, setCurrentUser] = useState<User>()
  const location = useLocation()

  const context = useContext(AuthContext)
  const firebaseUser = context?.firebaseUser ?? null

  useEffect(() => {
    getCurrentUser(firebaseUser).then((data) => setCurrentUser(data))
  }, [firebaseUser])

  useNavigateToCorrectPath(currentUser, location.pathname)

  const grantPermissionItems = [
    {
      key: '1',
      text: '1. In Sharing menu click Permissions',
      url: sharing,
      title: 'In Sharing menu click Permissions',
    },
    {
      key: '2',
      text: '2. Click Add Principal button',
      url: addPrincipal,
      title: 'Click Add Principal button',
    },
    {
      key: '3',
      text: '3. Select BigQuery Data Viewer for role',
      url: bigQueryDataViewer,
      title: 'Select BigQuery Data Viewer for role',
    },
  ]

  const progressBarProps: ProgressBarProps = {
    numberOfSteps: 4,
    stepsCompleted: 0,
  }

  const description = (
    <>
      <Typography color="secondary.dark" sx={{ m: '15px 0px' }} variant="body1">
        To learn your visitors’ behavior, we need access to their raw interaction data exported from Google Analytics to
        BigQuery. You can check the settings for{' '}
        <Link
          color="secondary.dark"
          href="https://support.google.com/analytics/answer/3416092?hl=en#zippy=%2Cin-this-article"
          variant="body1"
          rel="noopener"
          target="_blank"
        >
          Google Analytics 360
        </Link>{' '}
        and in{' '}
        <Link
          color="secondary.dark"
          href="https://support.google.com/analytics/answer/9823238?hl=en#zippy=%2Cin-this-article"
          variant="body1"
          rel="noopener"
          target="_blank"
        >
          GA4
        </Link>{' '}
        by following the links provided.
      </Typography>
      <Typography color="secondary.dark" sx={{ m: '15px 0px' }} variant="body1">
        We’ve created a the following email which for you have to grant{' '}
        <strong>
          <em>BigQuery Data Viewer </em>
        </strong>{' '}
        permission.
      </Typography>
    </>
  )

  return (
    <GrantPermission
      items={grantPermissionItems}
      progressBarProps={progressBarProps}
      targetUrl="/bigquery-configuration"
      serviceAccountEmail={currentUser?.serviceAccountEmail}
      description={description}
    />
  )
}
