import accountIdImage from '../../../assets/account_id.png'
import bigqueryDatasetIdImage from '../../../assets/bigquery_dataset_id.png'
import datasetLocationImage from '../../../assets/dataset_location.png'
import importDatasetIdImage from '../../../assets/import_dataset_id.png'
import projectIdImage from '../../../assets/project_id.png'
import propertyIdImage from '../../../assets/property_id.png'

export const ImageContainer = {
  projectId: new URL(projectIdImage, import.meta.url),
  bigqueryDatasetId: new URL(bigqueryDatasetIdImage, import.meta.url),
  importDatasetId: new URL(importDatasetIdImage, import.meta.url),
  datasetLocation: new URL(datasetLocationImage, import.meta.url),
  accountId: new URL(accountIdImage, import.meta.url),
  propertyId: new URL(propertyIdImage, import.meta.url),
}
